import React from "react";
import Menu from "../Menu";
import "./SideBar.css";

function SideBar({ isLoggedIn }) {
  return (
    <div className="side-bar">
      <a href="/">
        <svg class="svb-cleaned-logo" width="190" viewBox="0 0 500 109" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1204_1411)">
            <path class="logo-3" d="M166.88 34.3763H177.99C181.77 34.3763 184.91 33.1463 187.4 30.6963C189.89 28.2363 191.14 25.1863 191.14 21.5163C191.14 17.8463 189.89 14.7863 187.4 12.3363C184.91 9.87625 181.77 8.65625 177.99 8.65625H162.5V49.5863H166.89V34.3862L166.88 34.3763ZM166.88 12.7463H177.99C180.64 12.7463 182.76 13.5462 184.36 15.1462C185.96 16.7462 186.76 18.8663 186.76 21.5163C186.76 24.1663 185.96 26.2862 184.36 27.8862C182.76 29.4862 180.64 30.2863 177.99 30.2863H166.88V12.7463Z" fill="#010101"></path>
            <path class="logo-3" d="M230.61 49.5784H235V8.64844H231.2L206.05 41.6884V8.64844H201.67V49.5784H205.47L230.61 16.5484V49.5784Z" fill="#010101"></path>
            <path class="logo-3" d="M277.39 42.5681C274.39 44.9081 270.88 46.0781 266.86 46.0781C262.18 46.0781 258.24 44.4481 255.02 41.1981C251.8 37.9481 250.2 33.9181 250.2 29.1281C250.2 24.3381 251.81 20.3081 255.02 17.0481C258.24 13.7981 262.18 12.1681 266.86 12.1681C270.84 12.1681 274.29 13.3381 277.21 15.6781C278.5 16.6581 279.63 17.8181 280.6 19.1881L283.52 16.5581C282.39 14.9981 281.01 13.5981 279.37 12.3481C275.59 9.50813 271.42 8.07812 266.86 8.07812C261.01 8.07812 256.04 10.1281 251.95 14.2181C247.86 18.3081 245.81 23.2781 245.81 29.1281C245.81 34.9781 247.86 39.9481 251.95 44.0381C256.04 48.1281 261.01 50.1781 266.86 50.1781C271.46 50.1781 275.67 48.7781 279.49 45.9681C281.01 44.8381 282.45 43.4181 283.82 41.6981L280.9 39.0681C279.89 40.3981 278.72 41.5581 277.39 42.5781V42.5681Z" fill="#010101"></path>
            <path class="logo-3" d="M305.46 8.64844L288.5 49.5784H293.18L297.86 38.1784H317.16L321.84 49.5784H326.52L309.56 8.64844H305.47H305.46ZM299.61 34.0884L307.5 14.7884L315.39 34.0884H299.6H299.61Z" fill="#010101"></path>
            <path class="logo-3" d="M339.37 30.8684H362.17V49.5784H366.56V8.64844H362.17V26.7784H339.37V8.64844H334.98V49.5784H339.37V30.8684Z" fill="#010101"></path>
            <path class="logo-3" d="M189.45 92.6287C186.8 94.3887 184.15 95.2588 181.5 95.2588C178.3 95.2588 175.6 94.1587 173.4 91.9587C171.2 89.7587 170.1 87.0587 170.1 83.8587C170.1 80.6587 171.2 77.9588 173.4 75.7588C175.6 73.5588 178.3 72.4587 181.5 72.4587C184.19 72.4587 186.8 73.3387 189.33 75.0887C190.42 75.8687 191.42 76.7487 192.31 77.7188L199.03 70.1188C197.82 68.7188 196.36 67.4087 194.64 66.1987C190.78 63.5487 186.4 62.2188 181.48 62.2188C174.97 62.2188 169.54 64.3188 165.2 68.5088C160.85 72.6988 158.68 77.8187 158.68 83.8587C158.68 89.8987 160.85 95.0187 165.2 99.2087C169.55 103.399 174.97 105.499 181.48 105.499C186.51 105.499 190.93 104.189 194.75 101.579C196.47 100.449 197.98 99.1187 199.31 97.6087L192.59 90.0088C191.65 90.9788 190.6 91.8587 189.43 92.6387L189.45 92.6287Z" fill="#010101"></path>
            <path class="logo-3" d="M217.46 88.5367H232.95V79.1767H217.46V73.3267H237.63V63.3867H206.35V104.317H238.21V94.3767H217.46V88.5367Z" fill="#010101"></path>
            <path class="logo-3" d="M264.82 63.3867H246.4V104.317H257.51V92.0367H264.82C269.3 92.0367 272.96 90.6867 275.78 87.9767C278.61 85.2667 280.02 81.8467 280.02 77.7167C280.02 73.5867 278.61 70.1667 275.78 67.4567C272.95 64.7467 269.3 63.3967 264.82 63.3967V63.3867ZM267.54 80.7867C266.62 81.6667 265.52 82.0967 264.24 82.0967H257.52V73.3267H264.24C265.53 73.3267 266.63 73.7667 267.54 74.6467C268.46 75.5267 268.91 76.5467 268.91 77.7167C268.91 78.8867 268.45 79.9067 267.54 80.7867Z" fill="#010101"></path>
            <path class="logo-3" d="M317.09 84.8467C315.88 83.9467 314.52 83.2267 313 82.6867C314.25 82.2167 315.26 81.6567 316.04 80.9867C318.11 79.2367 319.14 76.9767 319.14 74.2067C319.14 71.1267 317.87 68.5567 315.34 66.4867C312.81 64.4167 309.3 63.3867 304.81 63.3867H286.68V104.317H306.27C310.91 104.317 314.55 103.227 317.2 101.037C319.85 98.8567 321.18 96.1467 321.18 92.9067C321.18 89.6667 319.82 86.9467 317.09 84.8367V84.8467ZM297.79 72.1567H303.93C305.1 72.1567 306.02 72.4767 306.71 73.1167C307.4 73.7567 307.73 74.6067 307.73 75.6567C307.73 76.7067 307.39 77.5567 306.71 78.1967C306.03 78.8367 305.1 79.1667 303.93 79.1667H297.79V72.1467V72.1567ZM308.55 94.3767C307.73 95.1567 306.68 95.5467 305.39 95.5467H297.79V87.3567H305.39C306.68 87.3567 307.73 87.7467 308.55 88.5267C309.37 89.3067 309.78 90.2767 309.78 91.4467C309.78 92.6167 309.37 93.5867 308.55 94.3667V94.3767Z" fill="#010101"></path>
            <path class="logo-3" d="M339.51 85.8967V63.3867H328.4V104.317H338.05L355.59 81.8067V104.317H366.7V63.3867H357.06L339.51 85.8967Z" fill="#010101"></path>
            <path class="logo-3" d="M407.85 89.9987C406.91 90.9687 405.86 91.8487 404.69 92.6287C402.04 94.3887 399.39 95.2588 396.74 95.2588C393.54 95.2588 390.84 94.1587 388.64 91.9587C386.44 89.7587 385.34 87.0587 385.34 83.8587C385.34 80.6587 386.44 77.9588 388.64 75.7588C390.84 73.5588 393.54 72.4587 396.74 72.4587C399.43 72.4587 402.04 73.3387 404.57 75.0887C405.66 75.8687 406.66 76.7487 407.56 77.7188L414.28 70.1188C413.07 68.7188 411.61 67.4087 409.89 66.1987C406.03 63.5487 401.65 62.2188 396.73 62.2188C390.22 62.2188 384.79 64.3188 380.45 68.5088C376.1 72.6988 373.93 77.8187 373.93 83.8587C373.93 89.8987 376.1 95.0187 380.45 99.2087C384.8 103.399 390.22 105.499 396.73 105.499C401.76 105.499 406.18 104.189 410 101.579C411.71 100.449 413.23 99.1187 414.56 97.6087L407.84 90.0088L407.85 89.9987Z" fill="#010101"></path>
            <path class="logo-2" d="M108.29 86.8008H0V108.461H108.29V86.8008Z" fill="#EF4123"></path>
            <path class="logo-2" d="M54.16 0L108.29 54.14L92.97 69.46L54.16 30.62L15.32 69.46L0 54.14L54.16 0Z" fill="#EF4123"></path>
            </g>
            <defs>
            <clipPath id="clip0_1204_1411">
            <rect width="414.57" height="108.46" fill="white"></rect>
            </clipPath>
            </defs>
        </svg>
      </a>
      <Menu isLoggedIn={isLoggedIn}/>
    </div>
  );
}

export default SideBar;
