import React from 'react';
import axios from 'axios';

function NewsItem({ title, text }) {

  return (
    <div>
      <div className="title-6 valign-text-middle poppins-normal-heavy-metal-20px">
        {title}
      </div>
      <img className="divider-2" src="../../static/img/divider-2@2x.svg" />
      <p className="text-1 poppins-normal-heavy-metal-15px">{text}</p>
    </div>
  );
}

export default NewsItem; 