import React from "react";
import './Auth.css';
import { useState } from 'react';
import AuthButton from "../AuthButton";

function Auth({ title }) {
  return (
    <div className="auth">
      <div className="title valign-text-middle poppins-semi-bold-heavy-metal-40px">
        {title}
      </div>
      <div className="phone-number-container">
        <AuthButton/>
      </div>
    </div>
  );
}

export default Auth;