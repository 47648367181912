import React from "react";
import { useEffect, useState } from 'react';
import axios from 'axios';
import SideBar from "../SideBar";
import * as Antd from "antd";
import { Collapse, Divider, Tabs } from 'antd';
import "./Chat.css";
import { Flex, Layout } from 'antd';

import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";

import moment from "moment";
import "moment/locale/ru";

function Chat() {
  const { Header, Footer, Sider, Content } = Layout;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [messagesService, setMessagesService] = useState([]);
  const [messagesSecurity, setMessagesSecurity] = useState([]);
  const [messagesRisan, setMessagesRisan] = useState([]);
  const [messagesIngeneering, setMessagesIngeneering] = useState([]);
  const newsList = { options: {"itemsPerPage": 10, "page": 1} };
  const chatServiceParams = {"user_id": -1, "type": "Service"};
  const chatSecurityParams = {"user_id": -1, "type": "Security"};
  const chatRisanParams = {"user_id": -1, "type": "Risan"};
  const chatIngeneeringParams = {"user_id": -1, "type": "Ingeneering"};
  const headers = { 
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
  };
  
  const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',
    width: 'calc(100% - 8px)',
    maxWidth: 'calc(100% - 8px)',
    backgroundColor: 'white',
    minWidth: '1200px',
  };
  
  const siderStyle: React.CSSProperties = {
    backgroundColor: 'white',
    position: 'fixed',
    height : '100%',
    zIndex: '100',
  };
  
  const contentStyle: React.CSSProperties = {
    textAlign: 'center',
  };
  
  const items = [
  {
    key: '1',
    label: 'Рисан Безопасность'
  },
  {
    key: '2',
    label: 'Рисан Сервис'
  },
  {
    key: '3',
    label: 'Рисан Инжиниринг'
  },
  {
    key: '4',
    label: 'Рисан'
  },
];
  
  useEffect(() => {
  
    axios.post('https://app.risan-service.ru/api/news/list', newsList, { headers })
      .then(response => {
        setLoading(false);
        setIsLoggedIn(true);
        axios.post('https://app.risan-service.ru/api/chat/list', chatServiceParams, { headers })
          .then(response => {
            setMessagesService(Object.values(response.data.data));
          })
          .catch(error => {
            window.location.href = "/";
          });
        axios.post('https://app.risan-service.ru/api/chat/list', chatSecurityParams, { headers })
          .then(response => {
            setMessagesSecurity(Object.values(response.data.data));
          })
          .catch(error => {
            window.location.href = "/";
          });
        axios.post('https://app.risan-service.ru/api/chat/list', chatIngeneeringParams, { headers })
          .then(response => {
            setMessagesIngeneering(Object.values(response.data.data));
          })
          .catch(error => {
            window.location.href = "/";
          });
        axios.post('https://app.risan-service.ru/api/chat/list', chatRisanParams, { headers })
          .then(response => {
            setMessagesRisan(Object.values(response.data.data));
          })
          .catch(error => {
            window.location.href = "/";
          });
      })
      .catch(error => {
        //запрос на обновление токена
		const authRefreshTokensParams = {
          token: localStorage.getItem("RefreshToken"),
        };
		axios
		  .post(
			"https://app.risan-service.ru/api/auth/refreshToken",
			authRefreshTokensParams
		  )
		  .then((response) => {
			//обновление Access-токена
			const AccessToken = response.data.token;
			localStorage.setItem("AccessToken", AccessToken);
			//получение данных
			setLoading(false);
            setIsLoggedIn(true);
            axios.post('https://app.risan-service.ru/api/chat/list', chatServiceParams, { headers })
              .then(response => {
                setMessagesService(Object.values(response.data.data));
              })
              .catch(error => {
                window.location.href = "/";
              });
            axios.post('https://app.risan-service.ru/api/chat/list', chatSecurityParams, { headers })
              .then(response => {
                setMessagesSecurity(Object.values(response.data.data));
              })
              .catch(error => {
                window.location.href = "/";
              });
            axios.post('https://app.risan-service.ru/api/chat/list', chatIngeneeringParams, { headers })
              .then(response => {
                setMessagesIngeneering(Object.values(response.data.data));
              })
              .catch(error => {
                window.location.href = "/";
              });
            axios.post('https://app.risan-service.ru/api/chat/list', chatRisanParams, { headers })
              .then(response => {
                setMessagesRisan(Object.values(response.data.data));
              })
              .catch(error => {
                window.location.href = "/";
              });
		  })
		  .catch(error => {
			setLoading(false);
			setIsLoggedIn(false);
			window.location.href = "/";
		  });
      });
    
  }, []);
  
  const handleServiceSend = (text:string) => {
    
    let messageParams = {"user_id":-1, "type": "Service", "message":{"text":text}};
    
    axios.post('https://app.risan-service.ru/api/chat/message', messageParams, { headers })
          .then(response => {
            axios.post('https://app.risan-service.ru/api/chat/list', chatServiceParams, { headers })
            .then(response => {
              setMessagesService(Object.values(response.data.data));
            })
            .catch(error => {
              window.location.href = "/";
            });
          })
          .catch(error => {
            window.location.href = "/";
          });  
  };
  
  const handleSecuritySend = (text:string) => {
    
    let messageParams = {"user_id":-1, "type": "Security", "message":{"text":text}};
    
    axios.post('https://app.risan-service.ru/api/chat/message', messageParams, { headers })
          .then(response => {
            axios.post('https://app.risan-service.ru/api/chat/list', chatSecurityParams, { headers })
            .then(response => {
              setMessagesSecurity(Object.values(response.data.data));
            })
            .catch(error => {
              window.location.href = "/";
            });
          })
          .catch(error => {
            window.location.href = "/";
          });  
  };
  
  const handleIngeneeringSend = (text:string) => {
    
    let messageParams = {"user_id":-1, "type": "Ingeneering", "message":{"text":text}};
    
    axios.post('https://app.risan-service.ru/api/chat/message', messageParams, { headers })
          .then(response => {
            axios.post('https://app.risan-service.ru/api/chat/list', chatIngeneeringParams, { headers })
            .then(response => {
              setMessagesIngeneering(Object.values(response.data.data));
            })
            .catch(error => {
              window.location.href = "/";
            });
          })
          .catch(error => {
            window.location.href = "/";
          });  
  };
  
  const handleRisanSend = (text:string) => {
    
    let messageParams = {"user_id":-1, "type": "Risan", "message":{"text":text}};
    
    axios.post('https://app.risan-service.ru/api/chat/message', messageParams, { headers })
          .then(response => {
            axios.post('https://app.risan-service.ru/api/chat/list', chatRisanParams, { headers })
            .then(response => {
              setMessagesRisan(Object.values(response.data.data));
            })
            .catch(error => {
              window.location.href = "/";
            });
          })
          .catch(error => {
            window.location.href = "/";
          });  
  };
  
  if (isLoading) {
    return <div className="container-center-horizontal">
      <div className="rw-home screen">
      </div>
    </div>;
  }
  
  return (
    <Layout style={layoutStyle}>
        <Sider 
            width="11%" style={siderStyle} 
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
        >
        <SideBar isLoggedIn={isLoggedIn}/>
        </Sider>
        <Layout>
          <Content style={contentStyle}>
          <div className="card-container">
            <div className="chat">
              <div className="title valign-text-middle poppins-semi-bold-heavy-metal-40px">
                Служба поддержки
              </div>
                            
              <div className="chat-container">
                <Tabs
                  defaultActiveKey="1"
                  centered
                  size="large"
                  animated={{ inkBar: true, tabPane: true }}
                >
                <Tabs.TabPane tab="Рисан Безопасность" key="1">
                    <div style={{ position: "relative", width: "80%", height: "65vh", marginTop: "15px", marginRight: "auto", marginLeft: "auto" }}>
                      <MainContainer>
                        <ChatContainer>
                          <MessageList>
                            {messagesSecurity.map((messageItem) => {

                              let text = messageItem.message.text;
                              let date = moment(messageItem.tm_from).locale('ru').format('DD MMMM YYYY, HH:mm');
                              let sender = messageItem.user_id == -1 ? "Оператор, " : "";
                              let direction = messageItem.user_id == -1 ? "incoming" : "outgoing";

                              console.log(date);

                              return (
                                <Message model={ { message: text, sentTime: date, sender: sender, direction : direction} } style={{marginBottom: "1.4em", marginTop: "1.4em"}}>
                                  <Message.Header>
                                    {sender}{date}
                                  </Message.Header>
                                </Message>
                              );
                            })}
                          </MessageList>
                          <MessageInput placeholder="Введите сообщение" onSend={handleSecuritySend} style={{paddingTop: "1.4em", paddingBottom: "1.4em"}}/>
                        </ChatContainer>
                      </MainContainer>
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Рисан Сервис" key="2">
                    <div style={{ position: "relative", width: "80%", height: "65vh", marginTop: "15px", marginRight: "auto", marginLeft: "auto" }}>
                      <MainContainer>
                        <ChatContainer>
                          <MessageList>
                            {messagesService.map((messageItem) => {

                              let text = messageItem.message.text;
                              let date = moment(messageItem.tm_from).locale('ru').format('DD MMMM YYYY, HH:mm');
                              let sender = messageItem.user_id == -1 ? "Оператор, " : "";
                              let direction = messageItem.user_id == -1 ? "incoming" : "outgoing";

                              console.log(date);

                              return (
                                <Message model={ { message: text, sentTime: date, sender: sender, direction : direction} } style={{marginBottom: "1.4em", marginTop: "1.4em"}}>
                                  <Message.Header>
                                    {sender}{date}
                                  </Message.Header>
                                </Message>
                              );
                            })}
                          </MessageList>
                          <MessageInput placeholder="Введите сообщение" onSend={handleServiceSend} style={{paddingTop: "1.4em", paddingBottom: "1.4em"}}/>
                        </ChatContainer>
                      </MainContainer>
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Рисан Инжиниринг" key="3">
                    <div style={{ position: "relative", width: "80%", height: "65vh", marginTop: "15px", marginRight: "auto", marginLeft: "auto" }}>
                      <MainContainer>
                        <ChatContainer>
                          <MessageList>
                            {messagesIngeneering.map((messageItem) => {

                              let text = messageItem.message.text;
                              let date = moment(messageItem.tm_from).locale('ru').format('DD MMMM YYYY, HH:mm');
                              let sender = messageItem.user_id == -1 ? "Оператор, " : "";
                              let direction = messageItem.user_id == -1 ? "incoming" : "outgoing";

                              console.log(date);

                              return (
                                <Message model={ { message: text, sentTime: date, sender: sender, direction : direction} } style={{marginBottom: "1.4em", marginTop: "1.4em"}}>
                                  <Message.Header>
                                    {sender}{date}
                                  </Message.Header>
                                </Message>
                              );
                            })}
                          </MessageList>
                          <MessageInput placeholder="Введите сообщение" onSend={handleIngeneeringSend} style={{paddingTop: "1.4em", paddingBottom: "1.4em"}}/>
                        </ChatContainer>
                      </MainContainer>
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Рисан" key="4">
                    <div style={{ position: "relative", width: "80%", height: "65vh", marginTop: "15px", marginRight: "auto", marginLeft: "auto" }}>
                      <MainContainer>
                        <ChatContainer>
                          <MessageList>
                            {messagesRisan.map((messageItem) => {

                              let text = messageItem.message.text;
                              let date = moment(messageItem.tm_from).locale('ru').format('DD MMMM YYYY, HH:mm');
                              let sender = messageItem.user_id == -1 ? "Оператор, " : "";
                              let direction = messageItem.user_id == -1 ? "incoming" : "outgoing";

                              console.log(date);

                              return (
                                <Message model={ { message: text, sentTime: date, sender: sender, direction : direction} } style={{marginBottom: "1.4em", marginTop: "1.4em"}}>
                                  <Message.Header>
                                    {sender}{date}
                                  </Message.Header>
                                </Message>
                              );
                            })}
                          </MessageList>
                          <MessageInput placeholder="Введите сообщение" onSend={handleRisanSend} style={{paddingTop: "1.4em", paddingBottom: "1.4em"}}/>
                        </ChatContainer>
                      </MainContainer>
                    </div>
                  </Tabs.TabPane>
                </Tabs>
              </div> 
            </div>
          </div>
        </Content>
        </Layout>
      </Layout>
  );
  
}

export default Chat;