import React from "react";
import { useEffect, useState } from 'react';
import axios from 'axios';
import SideBar from "../SideBar";
import { Flex, Layout } from 'antd';

import "./About.css";

function About() {
  const { Header, Footer, Sider, Content } = Layout;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const newsList = { options: {"itemsPerPage": 10, "page": 1} };
  const headers = { 
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
  };
  
  const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',
    width: 'calc(100% - 8px)',
    maxWidth: 'calc(100% - 8px)',
    backgroundColor: 'white',
    minWidth: '1200px',
  };
  
  const siderStyle: React.CSSProperties = {
    backgroundColor: 'white',
    position: 'fixed',
    height : '100%',
    zIndex: '100',
  };
  
  const contentStyle: React.CSSProperties = {
    textAlign: 'center',
  };
  
  //localStorage.removeItem('AccessToken');
  
  useEffect(() => {
  
    axios.post('https://app.risan-service.ru/api/news/list', newsList, { headers })
      .then(response => {
        setLoading(false);
        setIsLoggedIn(true);
    })
      .catch(error => {
        setLoading(false);
        setIsLoggedIn(false);
      });
  }, []);
  
  if (isLoading) {
    return <div className="container-center-horizontal">
      <div className="rw-home screen">
      </div>
    </div>;
  }

    
  return (
    <Layout style={layoutStyle}>
        <Sider 
            width="11%" style={siderStyle} 
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
        >
        <SideBar isLoggedIn={isLoggedIn}/>
        </Sider>
        <Layout>
          <Content style={contentStyle}>
          <div className="card-container">
            <div className="about">
              <div className="title valign-text-middle poppins-semi-bold-heavy-metal-40px">
                О компании
              </div>
              <div className="about-container">
                <p>&nbsp;</p>
                <p className="text-1 poppins-normal-heavy-metal-15px"><b>Управляющая компания «Рисан Сервис» — профессионалы в управлении жилой и коммерческой недвижимостью с опытом более 10 лет. Команда из 150 специалистов каждый день заботится о том, чтобы вы наслаждались жизнью и не отвлекались на бытовые проблемы.</b></p>
                <div class="company-cards">
                <div class="card-item">
                  <div class="card-item__icon">
                    <img src="https://risan-service.ru/local/templates/tvoydom/img/risan_small.svg" alt="" />
                  </div>
                  <div class="card-item__info">
                    <h3 class="js-count">2006</h3>

                    <p>год основания</p>
                  </div>
                </div>
                <div class="card-item">
                  <div class="card-item__icon">
                    <img src="https://risan-service.ru/local/templates/tvoydom/img/house.svg" alt="" />
                  </div>
                  <div class="card-item__info">
                    <h3 class="js-count">40</h3>
                    <p>многоквартирных домов</p>
                  </div>
                </div>
                <div class="card-item">
                  <div class="card-item__icon">
                    <img src="https://risan-service.ru/local/templates/tvoydom/img/ground.svg" alt="" />
                  </div>
                  <div class="card-item__info">
                    <h3>
                      <span class="js-count">804 889 </span><span> м²</span>
                    </h3>
                    <p>обслуживаемого жилья</p>
                  </div>
                </div>
              </div>
                <p className="text-1 poppins-normal-heavy-metal-15px"><b>Открытость и удобство</b></p>
                <p className="text-1 poppins-normal-heavy-metal-15px">Каждый месяц мы предоставляем дайджест с кратким перечнем работ, выполненных в жилых комплексах. Размещаем его на различных площадках — на сайте УК и в мобильных приложениях, на своём канале в Telegram и в официальных аккаунтах социальных сетей, а также на экранах в лифтах домов. Каждый житель может выбрать оптимальный для себя способ получения информации.</p>
                <p>&nbsp;</p>
                <p className="text-1 poppins-normal-heavy-metal-15px"><b>Совершенствуем работу подразделений</b></p>
                <p className="text-1 poppins-normal-heavy-metal-15px">Развели потоки обращений жильцов на аварийно-диспетчерскую службу (Рисан Сервис), службу безопасности (Рисан Безопасность), а также информационно-справочную службу (Рисан). Это позволило разгрузить специалистов и оперативнее справляться с задачами. Если вас волнуют начисления за услуги ЖКХ, прописка по месту жительства или вы хотите записаться на прием к директору, то нужно обратиться в информационно-справочную службу. А если нужно оставить заявку мастеру или экстренно вызвать слесаря, то следует звонить в аварийно-диспетчерскую службу.</p>
                <p>&nbsp;</p>
                <p className="text-1 poppins-normal-heavy-metal-15px"><b>Развиваем приложения</b></p>
                <p className="text-1 poppins-normal-heavy-metal-15px">Наши мобильные приложения изначально были оснащены базовыми функциями: заявка мастеру, оплата счетов, новости, полезные контакты. Но мы на этом не останавливаемся и тестируем новые, например, «Камеры». Теперь можно проверить, играет ли ваш ребенок в песочнице и узнать о свободном месте на парковке, заглянув в смартфон или на экран компьютера. Также мы запустили веб-версию Личного кабинета, чтобы информацию вы могли получать также на компьютере или ноутбуке.</p>
                <p>&nbsp;</p>
              </div>
            </div>
          </div>
        </Content>
        </Layout>
      </Layout>
  );
}

export default About;
