import React from "react";
import { useEffect, useState } from 'react';
import axios from 'axios';
import SideBar from "../SideBar";
import * as Antd from "antd";
import { Collapse, Divider } from 'antd';
import { Flex, Layout } from 'antd';

import "./Objects.css";

function Objects() {
  const { Header, Footer, Sider, Content } = Layout;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [regions, setRegions] = useState([]);
  const [lschets, setLschets] = useState([]);
  const regionListParams = [];
  let tmpRegionsNames = "";
  const lschetList = { };
  const headers = { 
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
  };
  
  const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',
    width: 'calc(100% - 8px)',
    maxWidth: 'calc(100% - 8px)',
    backgroundColor: 'white',
    minWidth: '1200px',
  };
  
  const siderStyle: React.CSSProperties = {
    backgroundColor: 'white',
    position: 'fixed',
    height : '100%',
    zIndex: '100',
  };
  
  const contentStyle: React.CSSProperties = {
    textAlign: 'center',
  };
  
  const renderLschet = (regionsItem) => {
    return <div>
        <p><b>Объект:</b> {regionsItem.title}</p>
        <p><b>Лицевой счёт:</b> <a target="_blank" href="https://dom.gosuslugi.ru/" title="Список лицевых счетов на портале «Госуслуги.Дом»">{regionsItem.lschet}</a></p>
        <a type="button" class="ant-btn ant-btn-lg ant-link" href="/video">
                                <span role="img" aria-label="video-camera" className="anticon anticon-video-camera">
                                  <svg viewBox="64 64 896 896" focusable="false" data-icon="video-camera" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 302.3L784 376V224c0-35.3-28.7-64-64-64H128c-35.3 0-64 28.7-64 64v576c0 35.3 28.7 64 64 64h592c35.3 0 64-28.7 64-64V648l128 73.7c21.3 12.3 48-3.1 48-27.6V330c0-24.6-26.7-40-48-27.7zM328 352c0 4.4-3.6 8-8 8H208c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h112c4.4 0 8 3.6 8 8v48zm560 273l-104-59.8V458.9L888 399v226z"></path>
                                  </svg>
                                </span>&nbsp;
                                <span>Камеры</span>
                              </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a type="button" class="ant-btn ant-btn-lg ant-link" href="/intercoms">
                                <span role="img" aria-label="video-camera" className="anticon anticon-video-camera">
                                  <svg viewBox="64 64 896 896" focusable="false" data-icon="video-camera" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 302.3L784 376V224c0-35.3-28.7-64-64-64H128c-35.3 0-64 28.7-64 64v576c0 35.3 28.7 64 64 64h592c35.3 0 64-28.7 64-64V648l128 73.7c21.3 12.3 48-3.1 48-27.6V330c0-24.6-26.7-40-48-27.7zM328 352c0 4.4-3.6 8-8 8H208c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h112c4.4 0 8 3.6 8 8v48zm560 273l-104-59.8V458.9L888 399v226z"></path>
                                  </svg>
                                </span>&nbsp;
                                <span>Домофоны</span>
                              </a>
    </div>
  }
  
  useEffect(() => {
  
    axios.post('https://app.risan-service.ru/api/lschet/list', lschetList, { headers })
      .then(response => {
        setLoading(false);
        setIsLoggedIn(true);
        setLschets(Object.values(response.data.lschets));
        let lschetList = Object.values(response.data.lschets);
        axios.post('https://app.risan-service.ru/api/region/list', regionListParams, { headers })
          .then(response => { 
            const tmpRegions = [];  
            response.data.forEach(function(item) {
              let tmpRegionsItem = [];
              let tmpRegionsNames = [];
              let lastRid = item.rids[item.rids.length - 1];
              lschetList.forEach(function(lschetItem) {
                tmpRegionsItem.title = "Информация не найдена";
                tmpRegionsItem.lschet = "Информация не найдена";
                if(lschetItem.region_id === lastRid) {
                   if(typeof lschetItem.region_name !== 'undefined') {
                     tmpRegionsItem.title = lschetItem.region_name;
                   }
                   
                   if(typeof lschetItem.lschet !== 'undefined') {
                     tmpRegionsItem.lschet = lschetItem.lschet;
                   }
                }
              });
              item.rnames.forEach(function(itemName) {
                tmpRegionsNames = tmpRegionsNames + itemName + ", ";
              });
              tmpRegionsNames = tmpRegionsNames.slice(0, -2);
              tmpRegionsItem.text = tmpRegionsNames;
              tmpRegions.push(tmpRegionsItem);
            });
            setRegions(tmpRegions);
          })
          .catch(error => {
            window.location.href = "/";
          });
      })
      .catch(error => {
        //запрос на обновление токена
		const authRefreshTokensParams = {
          token: localStorage.getItem("RefreshToken"),
        };
		axios
		  .post(
			"https://app.risan-service.ru/api/auth/refreshToken",
			authRefreshTokensParams
		  )
		  .then((response) => {
			//обновление Access-токена
			const AccessToken = response.data.token;
			localStorage.setItem("AccessToken", AccessToken);
			//получение данных
			setLoading(false);
            setIsLoggedIn(true);
            setLschets(Object.values(response.data.lschets));
            let lschetList = Object.values(response.data.lschets);
            axios.post('https://app.risan-service.ru/api/region/list', regionListParams, { headers })
              .then(response => { 
                const tmpRegions = [];  
                response.data.forEach(function(item) {
                  let tmpRegionsItem = [];
                  let tmpRegionsNames = [];
                  let lastRid = item.rids[item.rids.length - 1];
                  lschetList.forEach(function(lschetItem) {
                    tmpRegionsItem.title = "Информация не найдена";
                    tmpRegionsItem.lschet = "Информация не найдена";
                    if(lschetItem.region_id === lastRid) {
                       if(typeof lschetItem.region_name !== 'undefined') {
                         tmpRegionsItem.title = lschetItem.region_name;
                       }

                       if(typeof lschetItem.lschet !== 'undefined') {
                         tmpRegionsItem.lschet = lschetItem.lschet;
                       }
                    }
                  });
                  item.rnames.forEach(function(itemName) {
                    tmpRegionsNames = tmpRegionsNames + itemName + ", ";
                  });
                  tmpRegionsNames = tmpRegionsNames.slice(0, -2);
                  tmpRegionsItem.text = tmpRegionsNames;
                  tmpRegions.push(tmpRegionsItem);
                });
                setRegions(tmpRegions);
              })
              .catch(error => {
                window.location.href = "/";
              });
		  })
		  .catch(error => {
			setLoading(false);
			setIsLoggedIn(false);
			window.location.href = "/";
		  });
      });
    
  }, []);
  
  if (isLoading) {
    return <div className="container-center-horizontal">
      <div className="rw-home screen">
      </div>
    </div>;
  }
    
  return (
    <Layout style={layoutStyle}>
        <Sider 
            width="11%" style={siderStyle} 
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
        >
        <SideBar isLoggedIn={isLoggedIn}/>
        </Sider>
        <Layout>
          <Content style={contentStyle}>
          <div className="card-container">
            <div className="objects">
              <div className="title valign-text-middle poppins-semi-bold-heavy-metal-40px">
                Мои объекты
              </div>
                            
              <div className="objects-container">
                {
                  regions.map( regionsItem => {
                    
                    const lschet = "";
                    
                    return (
                      <div>
                      <Collapse
                          size="large"
                          style={{MozUserSelect: "none", WebkitUserSelect: "none", msUserSelect: "none"}}
                          items={[
                            {
                              key: '1',
                              label: regionsItem.text,
                              children: renderLschet(regionsItem),
                            },
                          ]}
                        />
                        <p></p>
                      </div>
                    )
                  })
                }
              </div> 
            </div>
          </div>
        </Content>
        </Layout>
      </Layout>
  );
}

export default Objects;
