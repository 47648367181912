import "./App.css";
import "./static/fonts/Geometria-Medium/style.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import RWHome from "./components/RWHome";
import Contacts from "./components/Contacts";
import NotFound from "./components/NotFound";
import Video from "./components/Video";
import Intercoms from "./components/Intercoms";
import About from "./components/About";
import Objects from "./components/Objects";
import Chat from "./components/Chat";
import Vacancies from "./components/Vacancies";
import Services from "./components/Services";
import Projects from "./components/Projects";


function App() {
  return (
    <Router>
        <Routes>
          <Route exact path="/" element={<RWHome/>}/>
          <Route exact path="/contacts" element={<Contacts/>}/>
          <Route exact path="/about" element={<About/>}/>
          <Route exact path="/video" element={<Video/>}/>
          <Route exact path="/intercoms" element={<Intercoms/>}/>
          <Route exact path="/objects" element={<Objects/>}/>
          <Route exact path="/chat" element={<Chat/>}/>
          <Route exact path="/vacancies" element={<Vacancies/>}/>
          <Route exact path="/services" element={<Services/>}/>
          <Route exact path="/projects" element={<Projects/>}/>
          <Route path="*" element={<NotFound/>}/>
        </Routes>
    </Router>
  );
}

export default App;
