import React from "react";
import axios from "axios";
import * as AntDesignIcons from "@ant-design/icons";
import * as Antd from "antd";
import "../../antd.css";
import "../../antd.variable.css";
import "./Menu.css";

function Menu({ isLoggedIn }) {
  
  
  const handleLogoutClick = () => {
    localStorage.removeItem('AccessToken');
    localStorage.removeItem('RefreshToken');
    window.location.href = "/";
  };
  
  
  return (
    <div className="menu">
      <div className="menu-1">
        <Antd.Menu
          style={{
            width: 300,
          }}
          theme="light"
        >
          
          {!isLoggedIn && <Antd.Menu.Item
            key="79"
            icon={<AntDesignIcons.LoginOutlined style={{ fontSize: '100%' }} />}
            style={{ fontSize: '150%', lineHeight: '180%' }} 
            onClick={(e) =>
              (window.location.href = "/")
            }
          >
            Войти
          </Antd.Menu.Item>}
          
          {isLoggedIn && <Antd.Menu.Item
            key="919"
            icon={<AntDesignIcons.FileTextOutlined style={{ fontSize: '100%' }} />}
            style={{ fontSize: '150%', lineHeight: '180%' }} 
            onClick={(e) =>
              (window.location.href = "/")
            }
          >
            Новости
          </Antd.Menu.Item>}
          
          {isLoggedIn && <Antd.Menu.Item
            key="979"
            icon={<AntDesignIcons.HomeOutlined style={{ fontSize: '100%' }} />}
            style={{ fontSize: '150%', lineHeight: '180%' }} 
            onClick={(e) =>
              (window.location.href = "/objects")
            }
          >
            Мой дом
          </Antd.Menu.Item>}
          
          {isLoggedIn && <Antd.Menu.Item
            key="679"
            icon={<AntDesignIcons.FormOutlined style={{ fontSize: '100%' }} />}
            style={{ fontSize: '150%', lineHeight: '180%' }} 
            onClick={(e) =>
              (window.location.href = "/chat")
            }
          >
            Мои заявки
          </Antd.Menu.Item>}
          
          {isLoggedIn && <Antd.Menu.Item
            key="609"
            icon={<AntDesignIcons.ShoppingCartOutlined style={{ fontSize: '100%' }} />}
            style={{ fontSize: '150%', lineHeight: '180%' }} 
            onClick={(e) =>
              (window.location.href = "/services")
            }
          >
            Услуги
          </Antd.Menu.Item>}
          
          {isLoggedIn && <Antd.Menu.Item
            key="779"
            icon={<AntDesignIcons.DatabaseOutlined style={{ fontSize: '100%' }} />}
            style={{ fontSize: '150%', lineHeight: '180%' }} 
            onClick={(e) =>
              (window.location.href = "/intercoms")
            }
          >
            Домофоны
          </Antd.Menu.Item>}
          
          {isLoggedIn && <Antd.Menu.Item
            key="729"
            icon={<AntDesignIcons.VideoCameraOutlined style={{ fontSize: '100%' }} />}
            style={{ fontSize: '150%', lineHeight: '180%' }} 
            onClick={(e) =>
              (window.location.href = "/video")
            }
          >
            Видео
          </Antd.Menu.Item>}
          
          {isLoggedIn && <Antd.Menu.Item
            key="158"
            icon={<AntDesignIcons.BankOutlined style={{ fontSize: '100%' }} />}
            style={{ fontSize: '150%', lineHeight: '180%' }} 
            onClick={(e) =>
              (window.location.href = "/projects")
            }
          >
            Витрина проектов
          </Antd.Menu.Item>}
            
          <Antd.Menu.Item
            key="179"
            icon={<AntDesignIcons.InfoCircleOutlined style={{ fontSize: '100%' }} />}
            style={{ fontSize: '150%', lineHeight: '180%' }} 
            onClick={(e) =>
              (window.location.href = "/about")
            }
          >
            О компании
          </Antd.Menu.Item>
          
          <Antd.Menu.Item
            key="321"
            icon={<AntDesignIcons.TeamOutlined style={{ fontSize: '100%' }} />}
            style={{ fontSize: '150%', lineHeight: '180%' }}  
            onClick={(e) =>
              (window.location.href = "/vacancies")
            }
          >
            Вакансии
          </Antd.Menu.Item>
            
          <Antd.Menu.Item
            key="422"
            icon={<AntDesignIcons.PhoneOutlined style={{ fontSize: '100%' }} />}
            style={{ fontSize: '150%', lineHeight: '180%' }}  
            onClick={(e) =>
              (window.location.href = "/contacts")
            }
          >
            Контакты
          </Antd.Menu.Item>
          
          
          {isLoggedIn && <Antd.Menu.Item
            key="379"
            icon={<AntDesignIcons.LogoutOutlined style={{ fontSize: '100%' }} />}
            style={{ fontSize: '150%', lineHeight: '180%' }} 
            onClick={handleLogoutClick}
          >
            Выйти
          </Antd.Menu.Item>}

        </Antd.Menu>
      </div>
    </div>
  );
}

export default Menu;
