import React from "react";
import axios from "axios";
import { useEffect, useState } from 'react';
import moment from "moment";
import "moment/locale/ru";
import "./NewsMain.css";
import NewsItem from "../NewsItem";
import { Flex, Layout } from 'antd';

function NewsMain({ title }) {
  const { Header, Footer, Sider, Content } = Layout;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [newsList, setNewsList] = useState([]);
  const newsListParams = { options: {"itemsPerPage": 10, "page": 1} };
  const headers = { 
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
  };
  
  useEffect(() => {
  
    axios.post('https://app.risan-service.ru/api/news/list', newsListParams, { headers })
      .then(response => {
        //получение данных
        setLoading(false);
        setIsLoggedIn(true);
        setNewsList(Object.values(response.data.data));
      })
      .catch(error => {
        //запрос на обновление токена
        const authRefreshTokensParams = {
          token: localStorage.getItem("RefreshToken"),
        };
		axios
		  .post(
			"https://app.risan-service.ru/api/auth/refreshToken",
			authRefreshTokensParams
		  )
		  .then((response) => {
            //обновление Access-токена
			const AccessToken = response.data.token;
			localStorage.setItem("AccessToken", AccessToken);
			//получение данных
            setLoading(false);
            setIsLoggedIn(true);
            setNewsList(Object.values(response.data.data));
		  })
		  .catch(error => {
			setLoading(false);
			setIsLoggedIn(false);
			window.location.href = "/";
		  });
      });
    
  }, []);
  
  if (isLoading) {
    return <div className="container-center-horizontal">
      <div className="rw-home screen">
      </div>
    </div>;
  }
  
  return (
    <div className="news-main">
      <div className="title valign-text-middle poppins-semi-bold-heavy-metal-40px">
        {title}
      </div>
      
      {
        newsList.map( newsListItem => {
          
          const newsDate = moment(newsListItem.tm_from).locale('ru').format('DD MMMM YYYY');
          
          return (
            <div className="news-main-container">
              <p className="text-1 poppins-normal-heavy-metal-15px"><b>{newsDate}</b></p>
              <div className="title-6 valign-text-middle poppins-normal-heavy-metal-20px">
                {newsListItem.title}
              </div>
              <svg width="142" height="20" viewBox="0 0 142 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1H141" stroke="#E2412E" stroke-width="2" stroke-linecap="round"/>
              </svg>
              <p className="text-1 poppins-normal-heavy-metal-15px" style={{whiteSpace: "pre-wrap"}}>{newsListItem.text}</p> 
              <p><img src={"https://app.risan-service.ru/imgs" + newsListItem.url} width="800" /></p>
            </div>
          )
        })
      }
    </div>
  );
}

export default NewsMain;
