import React from "react";
import { useEffect, useState } from 'react';
import axios from 'axios';
import SideBar from "../SideBar";
import { Card, Col, Row } from 'antd';
import { Flex, Layout } from 'antd';

import "./NotFound.css";

function NotFound() {
  const { Header, Footer, Sider, Content } = Layout;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const newsList = { options: {"itemsPerPage": 10, "page": 1} };
  const headers = { 
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
  };
  
  const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',
    width: 'calc(100% - 8px)',
    maxWidth: 'calc(100% - 8px)',
    backgroundColor: 'white',
    minWidth: '1200px',
  };
  
  const siderStyle: React.CSSProperties = {
    backgroundColor: 'white',
    position: 'fixed',
    height : '100%',
    zIndex: '100',
  };
  
  const contentStyle: React.CSSProperties = {
    textAlign: 'center',
  };
  
  //localStorage.removeItem('AccessToken');
  
  useEffect(() => {
  
    axios.post('https://app.risan-service.ru/api/news/list', newsList, { headers })
      .then(response => {
        setLoading(false);
        setIsLoggedIn(true);
    })
      .catch(error => {
        setLoading(false);
        setIsLoggedIn(false);
      });
  }, []);
  
  if (isLoading) {
    return <div className="container-center-horizontal">
      <div className="rw-home screen">
      </div>
    </div>;
  }
  
  return (
    <Layout style={layoutStyle}>
        <Sider 
            width="11%" style={siderStyle} 
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
        >
        <SideBar isLoggedIn={isLoggedIn}/>
        </Sider>
        <Layout>
          <Content style={contentStyle}>
          <div className="card-container">
            <div className="not-found">
              <div className="title valign-text-middle poppins-semi-bold-heavy-metal-40px">
                Страница не найдена
              </div>
              
              <div className="not-found-container">
                <p className="not-found-text">404</p>
              </div>
            </div>
          </div>
        </Content>
        </Layout>
      </Layout>
  );
}

export default NotFound;
