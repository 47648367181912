import React from "react";
import { useEffect, useState } from 'react';
import axios from 'axios';
import SideBar from "../SideBar";
import { Card, Col, Row, Modal } from 'antd';
import Iframe from "react-iframe";
import "./Video.css";
import { Flex, Layout } from 'antd';

const { Meta } = Card;

function Video() {
  const { Header, Footer, Sider, Content } = Layout;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [camVideos, setCamVideos] = useState([]);
  const [intercomVideos, setIntercomVideos] = useState([]);
  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState(false);
  const [modalVideoUrl, setModalVideoUrl] = useState(false);
  const [modalVideoId, setModalVideoId] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');
  const deviceListParams = { };
  const newsList = { options: {"itemsPerPage": 10, "page": 1} };
  const headers = { 
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
  };
  
  const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',
    width: 'calc(100% - 8px)',
    maxWidth: 'calc(100% - 8px)',
    backgroundColor: 'white',
    minWidth: '1200px',
  };
  
  const siderStyle: React.CSSProperties = {
    backgroundColor: 'white',
    position: 'fixed',
    height : '100%',
    zIndex: '100',
  };
  
  const contentStyle: React.CSSProperties = {
    textAlign: 'center',
  };
  
  const showModal = (video) => {
    setVisible(true);
    setModalTitle(video.rname + ". " + video.name);
    setModalVideoUrl(video.url.replace("1:1@", ""));
    setModalVideoId(video.id);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  
  useEffect(() => {
  
    axios.post('https://app.risan-service.ru/api/news/list', newsList, { headers })
      .then(response => {
        setLoading(false);
        setIsLoggedIn(true);
        axios.post('https://app.risan-service.ru/api/device/list', deviceListParams, { headers })
          .then(response => {
            setCamVideos(Object.values(response.data.video));
          })
          .catch(error => {
            window.location.href = "/";
          });
      })
      .catch(error => {
        //запрос на обновление токена
		const authRefreshTokensParams = {
          token: localStorage.getItem("RefreshToken"),
        };
		axios
		  .post(
			"https://app.risan-service.ru/api/auth/refreshToken",
			authRefreshTokensParams
		  )
		  .then((response) => {
			//обновление Access-токена
			const AccessToken = response.data.token;
			localStorage.setItem("AccessToken", AccessToken);
			//получение данных
			setLoading(false);
            setIsLoggedIn(true);
            axios.post('https://app.risan-service.ru/api/device/list', deviceListParams, { headers })
              .then(response => {
                setCamVideos(Object.values(response.data.video));
              })
              .catch(error => {
                window.location.href = "/";
              });
		  })
		  .catch(error => {
			setLoading(false);
			setIsLoggedIn(false);
			window.location.href = "/";
		  });
      });
    
  }, []);
  
  if (isLoading) {
    return <div className="container-center-horizontal">
      <div className="rw-home screen">
      </div>
    </div>;
  }
  
  
  const handleButtonClick = param => event => {
    setCurrentVideoUrl(param);
    setOpen(true);
  };
    
  return (
     <Layout style={layoutStyle}>
        <Sider 
            width="11%" style={siderStyle} 
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
        >
        <SideBar isLoggedIn={isLoggedIn}/>
        </Sider>
        <Layout>
          <Content style={contentStyle}>
          <div className="card-container">
            <div className="video">
              <div className="title valign-text-middle poppins-semi-bold-heavy-metal-40px">
                Видео с камер
              </div>
              <div className="video-container">
                <Modal
                  title={modalTitle}
                  visible={visible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  footer={null}
                  width={720}
                  style={{ textAlign: "center" }}
                >
                  <Iframe
                    url={modalVideoUrl}
                    width="100%"
                    height="504px"
                    id=""
                    className=""
                    display="block"
                    position="relative"
                    styles={{ marginBottom: "25px" }}
                  />

                </Modal> 
                
                <Row gutter={[24, 36]} justify="center">
                  
                  {camVideos.map((video) => {
                    let videoImg =
                      "https://app.risan-service.ru/imgs" + video.img;

                    return (
                      <Col xs={12} md={6} lg={8}>
                        <Card
                          hoverable
                          onClick={() => showModal(video)}
                          style={{
                            width: 240,
                            marginBottom: 8,
                          }}
                          cover={
                            <img src={videoImg} width="240" height="150" />
                          }
                        >
                          <Meta title={video.rname} description={video.name} />
                        </Card>
                      </Col>
                    );
                  })}
                  
                </Row>
              </div> 
            </div>
          </div>
        </Content>
        </Layout>
      </Layout>
  );
}

export default Video;
