import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import SideBar from "../SideBar";
import ModalVideo from "react-modal-video";
import * as AntDesignIcons from "@ant-design/icons";
import * as Antd from "antd";
import "../../antd.css";
import "../../antd.variable.css";
import Button from "../buttons/Button";
import { Card, Col, Row, Modal, message } from "antd";
import Iframe from "react-iframe";
import "./Intercoms.css";
import { Flex, Layout } from 'antd';

const { Meta } = Card;

function Intercoms() {
  const { Header, Footer, Sider, Content } = Layout;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState(false);
  const [modalVideoUrl, setModalVideoUrl] = useState(false);
  const [modalVideoId, setModalVideoId] = useState(false);
  const [isDisabled, setDisabled] = useState("");
  const [intercomVideos, setIntercomVideos] = useState([]);
  const [currentVideoUrl, setCurrentVideoUrl] = useState("");
  const deviceListParams = {};
  const newsList = { options: { itemsPerPage: 10, page: 1 } };
  const [messageApi, contextHolder] = message.useMessage();
  
  const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',
    width: 'calc(100% - 8px)',
    maxWidth: 'calc(100% - 8px)',
    backgroundColor: 'white',
    minWidth: '1200px',
  };
  
  const siderStyle: React.CSSProperties = {
    backgroundColor: 'white',
    position: 'fixed',
    height : '100%',
    zIndex: '100',
  };
  
  const contentStyle: React.CSSProperties = {
    textAlign: 'center',
  };

  const showModal = (video) => {
    setVisible(true);
    setModalTitle(video.rname + ". " + video.name);
    setModalVideoUrl(video.url.replace("1:1@", ""));
    setModalVideoId(video.id);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("AccessToken"),
  };

  //localStorage.removeItem('AccessToken');

  useEffect(() => {
    axios
      .post("https://app.risan-service.ru/api/news/list", newsList, { headers })
      .then((response) => {
        setLoading(false);
        setIsLoggedIn(true);
        axios
          .post(
            "https://app.risan-service.ru/api/device/list",
            deviceListParams,
            { headers }
          )
          .then((response) => {
            setIntercomVideos(Object.values(response.data.dom));
          })
          .catch((error) => {
            window.location.href = "/";
          });
      })
      .catch(error => {
        //запрос на обновление токена
		const authRefreshTokensParams = {
          token: localStorage.getItem("RefreshToken"),
        };
		axios
		  .post(
			"https://app.risan-service.ru/api/auth/refreshToken",
			authRefreshTokensParams
		  )
		  .then((response) => {
			//обновление Access-токена
			const AccessToken = response.data.token;
			localStorage.setItem("AccessToken", AccessToken);
			//получение данных
			setLoading(false);
            setIsLoggedIn(true);
            axios
              .post(
                "https://app.risan-service.ru/api/device/list",
                deviceListParams,
                { headers }
              )
              .then((response) => {
                setIntercomVideos(Object.values(response.data.dom));
              })
              .catch((error) => {
                window.location.href = "/";
              });
		  })
		  .catch(error => {
			setLoading(false);
			setIsLoggedIn(false);
			window.location.href = "/";
		  });
      });
  }, []);

  if (isLoading) {
    return (
      <div className="container-center-horizontal">
        <div className="rw-home screen"></div>
      </div>
    );
  }

  const handleButtonClick = (param) => (event) => {
    setCurrentVideoUrl(param);
    setOpen(true);
  };

  const handleOpenIntercomClick = (param) => {
    const openIntercomParams = { device_id: param };
    axios
      .post(
        "https://app.risan-service.ru/api/intercom/lockOpen",
        openIntercomParams,
        { headers }
      )
      .then((response) => {
        messageApi.open({
          type: 'success',
          content: 'Домофон открыт',
          duration: 5,
        });
      })
      .catch((error) => {
        if (error.response) {
          messageApi.open({
            type: 'error',
            content: 'Ошибка открытия домофона',
            duration: 5,
          });
        }
      });
  };

  return (
    <Layout style={layoutStyle}>
        <Sider 
            width="11%" style={siderStyle} 
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
        >
        <SideBar isLoggedIn={isLoggedIn}/>
        </Sider>
        <Layout>
          <Content style={contentStyle}>
          <div className="card-container">
            <div className="video">
              <div className="title valign-text-middle poppins-semi-bold-heavy-metal-40px">
                Домофоны
              </div>
              <div className="video-container">
                
                <Modal
                  title={modalTitle}
                  visible={visible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  footer={null}
                  width={720}
                  style={{ textAlign: "center" }}
                >
                  <Iframe
                    url={modalVideoUrl}
                    width="100%"
                    height="504px"
                    id=""
                    className=""
                    display="block"
                    position="relative"
                    styles={{ marginBottom: "25px" }}
                  />
                  {contextHolder}
                  <Antd.Button
                    type="danger"
                    shape="default"
                    size="large"
                    disabled={isDisabled}
                    onClick={() => handleOpenIntercomClick(modalVideoId)}
                    style={{ borderRadius: "4px" }}
                  >
                    Открыть
                  </Antd.Button>
                </Modal>

                <Row gutter={[24, 36]} justify="center">
                  
                  {intercomVideos.map((video) => {
                    let videoImg =
                      "https://app.risan-service.ru/imgs" + video.img;

                    return (
                      <Col xs={12} md={6} lg={8}>
                        <Card
                          hoverable
                          onClick={() => showModal(video)}
                          style={{
                            width: 240,
                            marginBottom: 8,
                          }}
                          cover={
                            <img src={videoImg} width="240" height="150" />
                          }
                        >
                          <Meta title={video.rname} description={video.name} />
                        </Card>
                      </Col>
                    );
                  })}
                  
                </Row>
                
              </div>
            </div>
          </div>
        </Content>
        </Layout>
      </Layout>
  );
}

export default Intercoms;
