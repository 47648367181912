import React from "react";
import { useEffect, useState } from 'react';
import axios from 'axios';
import SideBar from "../SideBar";
import { Card, Col, Row } from 'antd';
import { Flex, Layout } from 'antd';


import "./Contacts.css";

function Contacts() {
  const { Header, Footer, Sider, Content } = Layout;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const newsList = { options: {"itemsPerPage": 10, "page": 1} };
  const headers = { 
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
  };
  
 const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',
    width: 'calc(100% - 8px)',
    maxWidth: 'calc(100% - 8px)',
    backgroundColor: 'white',
    minWidth: '1200px',
  };
  
  const siderStyle: React.CSSProperties = {
    backgroundColor: 'white',
    position: 'fixed',
    height : '100%',
    zIndex: '100',
  };
  
  const contentStyle: React.CSSProperties = {
    textAlign: 'center',
  };
  
  //localStorage.removeItem('AccessToken');
  
  useEffect(() => {
  
    axios.post('https://app.risan-service.ru/api/news/list', newsList, { headers })
      .then(response => {
        setLoading(false);
        setIsLoggedIn(true);
    })
      .catch(error => {
        setLoading(false);
        setIsLoggedIn(false);
      });
  }, []);
  
  if (isLoading) {
    return <div className="container-center-horizontal">
      <div className="rw-home screen">
      </div>
    </div>;
  }

    
  return (
      <Layout style={layoutStyle}>
        <Sider 
            width="11%" style={siderStyle} 
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
        >
          <SideBar isLoggedIn={isLoggedIn}/>
        </Sider>
        <Layout>
          <Content style={contentStyle}>
            <div className="card-container">
              <div className="contacts">
                <div className="title valign-text-middle poppins-semi-bold-heavy-metal-40px">
                  ООО Рисан Сервис
                </div>

                <div className="contacts-container">

                  <Row gutter={[160, 160]}>
                    <Col xs={32} md={32} lg={32}>
                      <p className="text-1 poppins-normal-heavy-metal-15px"><b>Информационно-справочная служба:</b></p>
                      <p className="text-1 poppins-normal-heavy-metal-15px">+7(8412) 68-21-54</p>
                      <p className="text-1 poppins-normal-heavy-metal-15px"><b>Аварийно-диспетчерская служба:</b></p>
                      <p className="text-1 poppins-normal-heavy-metal-15px">+7(8412) 200-002</p>
                      <p className="text-1 poppins-normal-heavy-metal-15px"><b>Адрес эл. почты:</b></p>
                      <p className="text-1 poppins-normal-heavy-metal-15px"><a href="mailto:service@risan-penza.ru">service@risan-penza.ru</a></p>
                      <p>&nbsp;</p>
                      <p className="text-1 poppins-normal-heavy-metal-15px"><b>Режим работы:</b></p>
                      <p className="text-1 poppins-normal-heavy-metal-15px">ПН−ЧТ 8:00−17:00</p>
                      <p className="text-1 poppins-normal-heavy-metal-15px">ПТ 8:00−16:00</p>
                    </Col>
                    <Col xs={32} md={32} lg={32}>
                      <p className="text-1 poppins-normal-heavy-metal-15px"><b>Адреса филиалов:</b></p>
                      <p className="text-1 poppins-normal-heavy-metal-15px">ул. Мира, 40</p>
                      <p className="text-1 poppins-normal-heavy-metal-15px">ул. Пушкина, 7</p>
                      <p className="text-1 poppins-normal-heavy-metal-15px">ул. Долгорукова, 78</p>
                      <p className="text-1 poppins-normal-heavy-metal-15px">ул. Тепличная, 35</p>
                      <p className="text-1 poppins-normal-heavy-metal-15px">ул. Измайлова, 58А к3</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
  );
}

export default Contacts;
