import React from "react";
import { useEffect, useState } from 'react';
import axios from 'axios';
import SideBar from "../SideBar";
import * as Antd from "antd";
import { DeleteOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Collapse, Divider, Tabs } from 'antd';
import { Card, Col, Row, Modal } from 'antd';
import { InputNumber } from "antd";
import "./Services.css";
import { Flex, Layout } from 'antd';

import moment from "moment";
import "moment/locale/ru";

function Services() {
  const { Header, Footer, Sider, Content } = Layout;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const newsList = { options: {"itemsPerPage": 10, "page": 1} };
  const servicesParams = {"currency_code": "RUR"};
  const [services, setServices] = useState([]);
  const headers = { 
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
  };
  
  const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',
    width: 'calc(100% - 8px)',
    maxWidth: 'calc(100% - 8px)',
    backgroundColor: 'white',
    minWidth: '1200px',
  };
  
  const siderStyle: React.CSSProperties = {
    backgroundColor: 'white',
    position: 'fixed',
    height : '100%',
    zIndex: '100',
  };
  
  const contentStyle: React.CSSProperties = {
    textAlign: 'center',
  };
  
  const items = [
  {
    key: '1',
    label: 'Рисан Безопасность'
  },
  {
    key: '2',
    label: 'Рисан Сервис'
  },
  {
    key: '3',
    label: 'Рисан Инжиниринг'
  },
  {
    key: '4',
    label: 'Рисан'
  },
];
  
  useEffect(() => {
  
    axios.post('https://app.risan-service.ru/api/news/list', newsList, { headers })
      .then(response => {
        setLoading(false);
        setIsLoggedIn(true);
        axios
          .post('https://app.risan-service.ru/api/payment/serviceList', servicesParams, { headers })
          .then(response => {
            setServices(Object.values(response.data));
          })
          .catch(error => {
            window.location.href = "/";
          });
      })
      .catch(error => {
        //запрос на обновление токена
		const authRefreshTokensParams = {
          token: localStorage.getItem("RefreshToken"),
        };
		axios
		  .post(
			"https://app.risan-service.ru/api/auth/refreshToken",
			authRefreshTokensParams
		  )
		  .then((response) => {
			//обновление Access-токена
			const AccessToken = response.data.token;
			localStorage.setItem("AccessToken", AccessToken);
			//получение данных
			setLoading(false);
            setIsLoggedIn(true);
            axios
              .post('https://app.risan-service.ru/api/payment/serviceList', servicesParams, { headers })
              .then(response => {
                setServices(Object.values(response.data));
              })
              .catch(error => {
                window.location.href = "/";
              });
		  })
		  .catch(error => {
			setLoading(false);
			setIsLoggedIn(false);
			window.location.href = "/";
		  });
      });
    
  }, []);
  
  if (isLoading) {
    return <div className="container-center-horizontal">
      <div className="rw-home screen">
      </div>
    </div>;
  }
  
  return (
    <Layout style={layoutStyle}>
        <Sider 
            width="11%" style={siderStyle} 
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
        >
        <SideBar isLoggedIn={isLoggedIn}/>
        </Sider>
        <Layout>
          <Content style={contentStyle}>
          <div className="card-container">
            <div className="services">
              <div className="title valign-text-middle poppins-semi-bold-heavy-metal-40px">
                Услуги
              </div>
                            
              <div className="chat-container">
                <Tabs
                  defaultActiveKey="1"
                  centered
                  size="large"
                  animated={{ inkBar: true, tabPane: true }}
                  items = {items}
                />
                
                <Row gutter={[24, 36]}>
                  
                  {services.map((service) => {
                    
                    return (
                      <Col xs={12} md={6} lg={8}>
                        <Card
                          hoverable
                          style={{ padding: 10 }}
                        >
                          <Card.Meta
                            title={<h2>{service.name}</h2>}
                            description={service.name}
                          />
                          <br></br>
                          <Divider orientation='center'>{service.summ} руб.</Divider>
                          <Row gutter={[10]} className='add-cart-btn-row'>
                            <Col>
                              
                              <InputNumber controls={true} min={0} max={service.cur_in_work} defaultValue={0} style={{ marginRight: 10, width: "55px" }} />
                              
                              <Antd.Button
                                title="Добавить в заказ"
                                type="danger"
                                shape="default"
                                size="medium"
                              >
                                <ShoppingCartOutlined />
                              </Antd.Button>
                            </Col>
                            <Col>
                              <Antd.Button
                                title="Удалить из заказа"
                                type="danger"
                                shape="default"
                                size="medium"
                              >
                                <DeleteOutlined />
                              </Antd.Button>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    );
                  })}
                  
                </Row>
                
              </div> 
            </div>
          </div>
        </Content>
        </Layout>
      </Layout>
  );
  
}

export default Services;