import * as Antd from "antd";
import "../../../antd.css";
import "../../../antd.variable.css";
import React from "react";
import "./Button.css";

function Button({ text, icon, style = {} }) {
  return (
    <div className="button" style={style}>
      <Antd.Button
        type="ghost"
        shape="default"
        size="large"
        disabled={false}
        onClick={(e) => (window.location.href = "https://lk.risan-service.ru/")}
      >
        {icon}
        {text}
      </Antd.Button>
    </div>
  );
}

export default Button;
