import React from "react";
import axios from 'axios';
import { useEffect, useState } from 'react';
import SideBar from "../SideBar";
import NewsMain from "../NewsMain";
import Auth from "../Auth";
import { Flex, Layout } from 'antd';

import "./RWHome.css";

function RWHome() {
  const { Header, Footer, Sider, Content } = Layout;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const newsList = { options: {"itemsPerPage": 10, "page": 1} };
  const headers = { 
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
  };
  
  //localStorage.removeItem('AccessToken');
  
  const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',
    width: 'calc(100% - 8px)',
    maxWidth: 'calc(100% - 8px)',
    backgroundColor: 'white',
    minWidth: '1200px',
  };
  
  const siderStyle: React.CSSProperties = {
    backgroundColor: 'white',
    position: 'fixed',
    height : '100%',
    zIndex: '100',
  };
  
  const contentStyle: React.CSSProperties = {
    textAlign: 'center',
  };
  
  useEffect(() => {
  
    axios.post('https://app.risan-service.ru/api/news/list', newsList, { headers })
      .then(response => {
        setLoading(false);
        setIsLoggedIn(true);
    })
      .catch(error => {
        //запрос на обновление токена
		const authRefreshTokensParams = {
          token: localStorage.getItem("RefreshToken"),
        };
		axios
		  .post(
			"https://app.risan-service.ru/api/auth/refreshToken",
			authRefreshTokensParams
		  )
		  .then((response) => {
			//обновление Access-токена
			const AccessToken = response.data.token;
			localStorage.setItem("AccessToken", AccessToken);
		  })
		  .catch(error => {
			setLoading(false);
			setIsLoggedIn(false);
		  });
      });
  }, []);
  
  if (isLoading) {
    return <div className="container-center-horizontal">
      <div className="rw-home screen">
      </div>
    </div>;
  }
    
  return (
     <Layout style={layoutStyle}>
        <Sider 
            style={siderStyle} 
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
        >
          <SideBar isLoggedIn={isLoggedIn}/>
        </Sider>
        <Layout>
          <Content width="89%" style={contentStyle}>
          <div className="card-container">
            {isLoggedIn && <NewsMain title="Новости"/>}
            {!isLoggedIn && <Auth title="Вход в Личный кабинет" />} 
          </div>
        </Content>
        </Layout>
    </Layout>
  );
}

export default RWHome;
