import React from "react";
import { useEffect, useState } from "react";
import * as AntDesignIcons from "@ant-design/icons";
import * as Antd from "antd";
import "../../antd.css";
import "../../antd.variable.css";
import Input from "react-phone-number-input/input";
import ReactInputVerificationCode from "react-input-verification-code";
import { message } from "antd";
import md5 from "md5";
import axios from "axios";

function AuthButton() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showCodeInput, setShowCodeInput] = useState("");
  const [codeInput, setCodeInput] = useState("");
  const [isDisabled, setDisabled] = useState("");
  const [authState, setAuthState] = useState("");
  const tokenTmp = "";
  const authPhoneDebugParams = { phone: phoneNumber };
  const [messageApi, contextHolder] = message.useMessage();

  const handleAuthGetCodeClick = () => {
    axios
      .post(
        "https://app.risan-service.ru/api/auth/authorize",
        authPhoneDebugParams
      )
      .then((response) => {
        const tokenTmp = response.data.token;
        localStorage.setItem("TmpToken", tokenTmp);
        setShowCodeInput(true);
        setDisabled(true);
      })
      .catch((error) => {
        if (error.response) {
          messageApi.open({
            type: "error",
            content: "Ошибка авторизации",
            duration: 5,
          });
        }
      });
  };

  const handleAuthButtonClick = () => {
    const hashedTokenWithCode = md5(
      localStorage.getItem("TmpToken") + codeInput
    );
    const authGetTokensParams = {
      token: localStorage.getItem("TmpToken"),
      hash: hashedTokenWithCode,
    };
    axios
      .post(
        "https://app.risan-service.ru/api/auth/checkToken",
        authGetTokensParams
      )
      .then((response) => {
        const AccessToken = response.data.token;
        const RefreshToken = response.data.refresh;
        localStorage.setItem("AccessToken", AccessToken);
        localStorage.setItem("RefreshToken", RefreshToken);
        window.location.href = "/";
      })
      .catch((error) => {
        if (error.response) {
          messageApi.open({
            type: "error",
            content: "Код введён неправильно",
            duration: 1,
          });
        }
      });
  };

  const handleSubmit = (e, param) => {
    e.preventDefault();
    if (param === true) {
      handleAuthButtonClick();
    } else {
      handleAuthGetCodeClick();
    }
  };

  const handleCodeInput = (value) => {
    const exactValue = value.replace(/·/g, '');
    if (exactValue.length > 5) {
      setCodeInput(exactValue);
      handleAuthButtonClick();
    }
  };

  return (
    <form onSubmit={(e) => handleSubmit(e, showCodeInput)}>
      {contextHolder}
      <p className="poppins-normal-heavy-metal-15px">
        Введите номер телефона и нажмите кнопку «Получить код» или клавишу Enter
      </p>
      <Input
        className="phone-number-input"
        country="RU"
        international
        withCountryCallingCode
        value={phoneNumber}
        onChange={setPhoneNumber}
        disabled={isDisabled}
        maxLength="16"
        autoFocus={true}
      />
      <div className="button phone-number-button">
        <Antd.Button
          type="danger"
          shape="default"
          size="large"
          disabled={isDisabled}
          onClick={handleAuthGetCodeClick}
          style={{ borderRadius: "4px", paddingTop: "4px" }}
        >
          Получить код
        </Antd.Button>
      </div>
      {showCodeInput && (
        <div>
          <p className="poppins-normal-heavy-metal-15px code-input-text">
            Введите код из СМС и нажмите кнопку «Продолжить»
          </p>
          <ReactInputVerificationCode
            length={6}
            autoFocus={true}
            onChange={setCodeInput}
          />
          <div className="button code-input-button">
            <Antd.Button
              type="danger"
              shape="default"
              size="large"
              disabled={false}
              onClick={handleAuthButtonClick}
              style={{ borderRadius: "4px", paddingTop: "4px" }}
            >
              Продолжить
            </Antd.Button>
          </div>
        </div>
      )}
    </form>
  );
}

export default AuthButton;
