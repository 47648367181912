import React from "react";
import axios from "axios";
import { useEffect, useState } from 'react';
import moment from "moment";
import Iframe from "react-iframe";
import SideBar from "../SideBar";
import "moment/locale/ru";
import "./Projects.css";
import { Card, Col, Row, Modal } from 'antd';
import { Flex, Layout } from 'antd';
const { Meta } = Card;

function Projects({ title }) {
  const { Header, Footer, Sider, Content } = Layout;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState(false);
  const [modalProjectUrl, setModalProjectUrl] = useState(false);
  const [modalProjectId, setModalProjectId] = useState(false);
  const [projectsList, setProjectsList] = useState([]);
  const projectsListParams = { options: {"itemsPerPage": 20, "page": 1} };
  const newsList = { options: {"itemsPerPage": 10, "page": 1} };
  const headers = { 
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
  };
  
  const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',
    width: 'calc(100% - 8px)',
    maxWidth: 'calc(100% - 8px)',
    backgroundColor: 'white',
    minWidth: '1200px',
  };
  
  const siderStyle: React.CSSProperties = {
    backgroundColor: 'white',
    position: 'fixed',
    height : '100%',
    zIndex: '100',
  };
  
  const contentStyle: React.CSSProperties = {
    textAlign: 'center',
  };
  
  const showModal = (project) => {
    setVisible(true);
    setModalTitle(project.rname);
    const iframe = project.iframe.split('\"');
    let iframeSrc = iframe[1];
    setModalProjectUrl(iframeSrc);
    setModalProjectId(project.id);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  
  useEffect(() => {
    
    axios.post('https://app.risan-service.ru/api/news/list', newsList, { headers })
      .then(response => {
        setLoading(false);
        setIsLoggedIn(true);
        axios.post('https://app.risan-service.ru/api/showcase/list', projectsListParams, { headers })
          .then(response => {
            setLoading(false);
            setIsLoggedIn(true);
            setProjectsList(Object.values(response.data.showcases));
          })
          .catch(error => {
            window.location.href = "/";
          });
      })
      .catch(error => {
        //запрос на обновление токена
		const authRefreshTokensParams = {
          token: localStorage.getItem("RefreshToken"),
        };
		axios
		  .post(
			"https://app.risan-service.ru/api/auth/refreshToken",
			authRefreshTokensParams
		  )
		  .then((response) => {
			//обновление Access-токена
			const AccessToken = response.data.token;
			localStorage.setItem("AccessToken", AccessToken);
			//получение данных
			setLoading(false);
            setIsLoggedIn(true);
            setProjectsList(Object.values(response.data.showcases));
		  })
		  .catch(error => {
			setLoading(false);
			setIsLoggedIn(false);
			window.location.href = "/";
		  });
      });
 
    
  }, []);
  
  if (isLoading) {
    return <div className="container-center-horizontal">
      <div className="rw-home screen">
      </div>
    </div>;
  }
  
  return (
    <Layout style={layoutStyle}>
        <Sider 
            width="11%" style={siderStyle} 
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
        >
        <SideBar isLoggedIn={isLoggedIn}/>
        </Sider>
        <Layout>
          <Content style={contentStyle}>
            <div className="card-container">
              <div className="projects">
                <div className="title valign-text-middle poppins-semi-bold-heavy-metal-40px">
                  Витрина проектов
                </div>

                <div className="projects-container">
                  
                <Modal
                  title={modalTitle}
                  visible={visible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  footer={null}
                  width={720}
                  style={{ textAlign: "center" }}
                >
                  <Iframe
                      url={modalProjectUrl}
                      width="100%"
                      height="604px"
                      id=""
                      className=""
                      display="block"
                      position="relative"
                      styles={{ marginBottom: "25px" }}
                  />

                </Modal> 
                  
                  
                  <Row gutter={[24, 36]} justify="center">
                  
                    {projectsList.map((project) => {
                      let projectImg =
                        "https://app.risan-service.ru/imgs" + project.main_images[0].url;

                      let projectDesc = "Дата сдачи: " + project.deadline + " год";
                      
                      return (
                        <Col xs={12} md={6} lg={8}>
                          <Card
                            hoverable
                            onClick={() => showModal(project)}
                            style={{
                              width: 240,
                              marginBottom: 8,
                            }}
                            cover={
                              <img src={projectImg} width="240" height="150" />
                            }
                          >
                            <Meta title={project.rname} description={projectDesc} />
                          </Card>
                        </Col>
                      );
                    })}

                  </Row>
                  
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
  );
}

export default Projects;
