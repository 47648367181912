import React from "react";
import { useEffect, useState } from 'react';
import { Card, Col, Row, Modal } from 'antd';
import axios from 'axios';
import SideBar from "../SideBar";
import { Flex, Layout } from 'antd';

import "./Vacancies.css";

function Vacancies() {
  const { Header, Footer, Sider, Content } = Layout;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [vacanciesList, setVacanciesList] = useState([]);
  const newsList = { options: {"itemsPerPage": 10, "page": 1} };
  const headers = { 
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
  };
  
  const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',
    width: 'calc(100% - 8px)',
    maxWidth: 'calc(100% - 8px)',
    backgroundColor: 'white',
    minWidth: '1200px',
  };
  
  const siderStyle: React.CSSProperties = {
    backgroundColor: 'white',
    position: 'fixed',
    height : '100%',
    zIndex: '100',
  };
  
  const contentStyle: React.CSSProperties = {
    textAlign: 'center',
  };
  
  //localStorage.removeItem('AccessToken');
  
  useEffect(() => {
  
    axios.post('https://app.risan-service.ru/api/news/list', newsList, { headers })
      .then(response => {
        setLoading(false);
        setIsLoggedIn(true);
    })
      .catch(error => {
        setLoading(false);
        setIsLoggedIn(false);
      });
    
    
    fetch('https://api.hh.ru/vacancies?employer_id=2146523')
     .then((response) => response.json())
     .then((responseJson) => {
       setVacanciesList(responseJson.items);
     })
     .catch((error) => {
       console.error(error);
     });
    
  }, []);
  
  if (isLoading) {
    return <div className="container-center-horizontal">
      <div className="rw-home screen">
      </div>
    </div>;
  }

    
  return (
    <Layout style={layoutStyle}>
        <Sider 
            width="11%" style={siderStyle} 
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
        >
          <SideBar isLoggedIn={isLoggedIn}/>
        </Sider>
        <Layout>
          <Content width="89%" style={contentStyle}>
            <div className="card-container">
              <div className="vacancies">
                <div className="title valign-text-middle poppins-semi-bold-heavy-metal-40px">
                  Вакансии
                </div>
                <div className="vacancies-container">
                  <Row gutter={[36, 36]}>
                    {
                      vacanciesList.map( vacancy => {
                        return (
                          <Col span={24}>
                            <Card title={vacancy.name} bordered={true} style={{ width: '100%', height: '100%'}}>
                              <p><b>Опыт работы:</b> {vacancy.experience.name}</p>
                              <p><b>Требования:</b> {vacancy.snippet.requirement}</p>
                              <p><b>Обязанности:</b> {vacancy.snippet.responsibility}</p>
                              <p><b>Подробнее о вакансии:</b> <a target="_blank" href={vacancy.alternate_url}>{vacancy.alternate_url}</a></p>
                            </Card>
                          </Col>
                        )
                      })
                    }
                  </Row>
                </div>
              </div>
          </div>
        </Content>
        </Layout>
      </Layout>
  );
}

export default Vacancies;
